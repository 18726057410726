import React, { useState } from "react"

import LogoSVG from "../../assets/logo.svg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import addToMailchimp from 'gatsby-plugin-mailchimp'

import styled from 'styled-components';

const Title = styled.h1`
  font-size: 3em;
  text-align: left;
  font-weight: black;
`;

const Subtitle = styled.p`
  font-size: 1.5em;
  text-align: left;
  font-family: sans-serif;
  font-weight: 500;
`;

const SignUpText = styled.p`
  font-size: 1em;
  text-align: left;
  font-family: sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
`;


const Container = styled.div`
  font-size: 1.5em;
  background-color: #FECB2E;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const LeftSubContainer = styled.div`
  padding: 25px 100px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-align: left;
`;


const RightSubContainer = styled.div`
  padding: 25px 25px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-align: left;
`;

const BottomLeftContainer = styled.div`
  width: 100%;
`

const EmailFormContainer = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 10px;
  padding: 0px;
`

const EmailForm = styled.form`
  border-radius: 100px;
  width: 100%;
  height: 100%;
  flex-direction: row;
  font-family: sans-serif;
  font-weight: 600;
  padding: 0px;
  align-items: center;
  justify-content: space-between;
`

const EmailInput = styled.input`
  height: 100%;
  margin: 0 auto;
  margin-top:10px;
  border: none;
  border:solid 0px #ccc;
  border-radius: 10px;
`

const SubmitButton = styled.input`
  background-color: transparent;
  height: 100%;
  font-size: 1em;
  color: black;
  margin: 0px 20px;
  border: none;
  border: solid 2px green;
  border-radius: 10px;

`

const ICON_SIZE = 150
const IndexPage = () => {
  const [email, setEmail] = useState('');

  const _handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { msg, result } = await addToMailchimp(email, {})
      console.log('msg', `${result}: ${msg}`);
      if (result !== 'success') {
        throw msg;
      }
      alert(msg);
    }
    catch (err) {
      console.log('err', err, email);
      alert(err);
    }
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Container>
        <LeftSubContainer>
          <LogoSVG style={{ width: ICON_SIZE, height: ICON_SIZE, marginLeft: -15 }} />
          <Title>Ravrr</Title>
          <Subtitle style={{ paddingBottom: 60 }}>Connect. Energize. Rave.</Subtitle>
          <SignUpText>Join the Waitlist.</SignUpText>
          <EmailFormContainer>
            <EmailForm onSubmit={_handleSubmit}>
              <EmailInput type="email" onChange={(event) => { setEmail(event.target.value.toLowerCase()) }} />
              <SubmitButton type="submit" />
            </EmailForm>
          </EmailFormContainer>
          {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
          <Image />
        </div> */}
        </LeftSubContainer>
        <RightSubContainer>

        </RightSubContainer>
      </Container>
    </Layout>
  )
}

export default IndexPage
